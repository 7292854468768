import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ChartComponent } from '../../../../shared/components/chart/chart.component'
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { HeadingComponent } from '../../../../shared/components/heading/heading.component'
import { ProjectService } from '../../../../shared/services/project.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import moment from 'moment';
import { TimeKeepingService } from '../../../../shared/services/time-keeping.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserService } from '../../../../shared/services/user.service';
import { EmittersService } from '../../../../shared/services/emitters.service';
@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [ChartComponent, ChipModule, FormsModule, ButtonModule, DropdownModule, HeadingComponent, RouterModule, CommonModule, MultiSelectModule],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class OverviewComponent implements OnInit {
  checked: boolean = false;
  chartLabels: any = [];
  datasets = [
    {
      label: 'Graph',
      data: [],
      backgroundColor: ['rgba(255, 159, 64, 0.2)'],
      borderColor: ['rgb(255, 159, 64)'],
      borderWidth: 1,
    }
  ]
  firmId = '';
  selectedCity: any;
  projectSnapshots: any;
  projectDetails: any;
  projects: any;
  projectRoles: any;
  selectedProjects: any = [];
  selectedProjectNames: any = [];
  selectedRoles: any = [];
  selectedRoleNames: any = [];
  dailyDuration = { hours: '00', minutes: '00', seconds: '00' };
  weekDuration = { hours: '00', minutes: '00' };
  totalDuration = { hours: '00', minutes: '00' };
  headingButton = {
    url: '/client/project/create',
    label: 'CREATE PROJECT'
  }
  
  constructor(
    private projectService: ProjectService, 
    private userService: UserService,
    private timeKeepingService: TimeKeepingService, 
    private router:Router,
    private emittersService: EmittersService
  ) {}
  

  ngOnInit() {

    const user = this.userService.getSessionUser();

    this.firmId = user.firm._id;
    this.initialization();  
    this.subscribeEvents();  
  }

  subscribeEvents(){
    this.emittersService.myProjectEmitter.subscribe(() => {
      this.initialization();
    })
  }

  initialization(){
    this.getProjectsOverview();

    this.getProjects();

    this.getTsheetList();
  }

  getProjectsOverview(){
    this.projectService.getProjectsOverview('all').subscribe({
      next: (overview) => {

        this.projectSnapshots = [
          {
            title: "Published Projects",
            count: overview ? (overview.PUBLISHED ? overview.PUBLISHED.count : 0) : 0,
            asset: "published_with_changes",
            class: 'material-symbols-outlined',
            route: '/client/project/published'
          },
          {
            title: "Unpublished Projects",
            count: overview ? (overview.CREATED ? overview.CREATED.count : 0) : 0,
            asset: "unpublished",
            class: 'material-symbols-outlined background-red',
            route: '/client/project/unpublished'
          },
          {
            title: "Started Projects",
            count: overview ? (overview.STARTED ? overview.STARTED.count : 0) : 0,
            asset: "restart_alt",
            class: 'material-symbols-outlined orange',
            route: '/client/project/started'
          },
          {
            title: "Archived Projects",
            count: overview ? (overview.ARCHIVED ? overview.ARCHIVED.count : 0) : 0,
            asset: "archive",
            class: 'material-symbols-outlined green',
            route: '/client/project/archived'
          }
        ]

        this.projectDetails = [];

        if(overview.CREATED){
          this.projectDetails.push({
            heading: 'Last Project Edited',
            project: overview.CREATED
          });
        }
        if(overview.PUBLISHED){
          this.projectDetails.push({
            heading: 'Last Project Published',
            project: overview.PUBLISHED
          });
        }
        if(overview.STARTED){
          this.projectDetails.push({
            heading: 'Last Project Started',
            project: overview.STARTED
          });
        }
      }
    })
  }

  getProjects(){
    this.projectService.getProjectsByFirmAndMarkets(this.firmId, [], ['STARTED', 'PUBLISHED']).subscribe({
      next: (projects) => {
        this.projects = projects;
      }
    })
  }

  getProjectRoles(){
    // reset role values
    this.selectedRoles = [];
    this.selectedRoleNames = [];

    this.projectService.getMultipleProjectRoles(this.selectedProjects).subscribe({
      next: (roles) => {
        this.projectRoles = roles;
      }
    })
  }

  onProjectChange(event: any) {
    this.selectedProjects = event.value;
    let selectedProjectsObj = this.projects.filter((e: any) => this.selectedProjects.includes(e._id));
    this.selectedProjectNames = selectedProjectsObj.map((e: any) => e.codeName);
    this.getProjectRoles();
  }

  onRoleChange(event: any) {
    this.selectedRoles = event.value;
    let selectedRolesObj = this.projectRoles.filter((e: any) => this.selectedRoles.includes(e._id));
    this.selectedRoleNames = selectedRolesObj.map((e: any) => e.roleType);
    this.getTsheetList();
  }

  getCurrentWeek() {
    let startOfWeek = moment().isoWeekday(1);
    startOfWeek = startOfWeek.startOf('day');
    let endOfWeek = moment(startOfWeek).add('days', 6);
    endOfWeek = endOfWeek.endOf('day');
    return { startDate: startOfWeek, endDate: endOfWeek };
  }

  calcTotalDuration(tsheets: any) {
    let totalSecs = 0;
    tsheets = tsheets || [];
    tsheets.forEach((tsheet: any) => {
      totalSecs += tsheet.duration;
    });
    return tsheets;
  }

  calcDailyDuration(tsheets: any) {
    let dailySecs = 0;
    tsheets = tsheets || [];
    tsheets.forEach((tsheet: any) => {
      dailySecs += tsheet.duration;
    });
    this.dailyDuration = this.timeKeepingService.secondsToHMS(dailySecs);
    return tsheets;
  }

  calcWeekDuration(tsheets: any) {
    let weekSecs = 0;
    tsheets = tsheets || [];
    tsheets.forEach((tsheet: any) => {
      if (tsheet.onTheClock) {
        let clockedInTimeSheet = tsheet.id;
      }
      weekSecs += tsheet.duration;
    });
    this.weekDuration = this.timeKeepingService.secondsToHMS(weekSecs);
    return tsheets;
  }

  getTsheetList() {
    let payload: any = {};

    let navigatedWeek = this.getCurrentWeek();
    payload = {
      firmId: this.firmId,
      projectIds: this.selectedProjects,
      roleIds: this.selectedRoles,
    };

    if (navigatedWeek && navigatedWeek.startDate) {
      payload.dateRange = navigatedWeek;
      payload.inclusive = true;
    }

    return this.timeKeepingService.getTsheetsByFirm(payload)
      .subscribe((response: any) => {

        let weekTsheets = this.calcWeekDuration(response.week);
        let tsheets = this.calcTotalDuration(response.list);
        let dailyTsheets = this.calcDailyDuration(response.daily);
        this.chartLabels = Object.keys(response.stats.weeklyDistributedHours).map(function (k) {
            let key = k;
            let x = key.split('/');
            return x[0] + '/' + x[1];
        });

        this.datasets[0].data = [];
        let graphDataPoints: any = [];
        Object.keys(response.stats.weeklyDistributedHours).forEach(function (key) {
            graphDataPoints.push(Number(response.stats.weeklyDistributedHours[key].dailyTotalHours.toFixed(2)));
        });
        this.datasets[0].data = graphDataPoints;
        this.totalDuration = response.stats.totalHoursOnProject;
      })
  }

  onEdit($event:Event, project: any): void{
    $event.stopPropagation();
    this.router.navigateByUrl(`/client/project/${project._id}/edit`);
  }
}
