<app-heading [title]="'People'" [showToggle]="false" [button]="headingButton" [extraButton]="!userObject.firm.reviewerAutoApproval && pendingCandidateButton"></app-heading>
<div class="projects-data all-people-container">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0">
            <div class="grid grid-nogutter">
                <div class="col-12 md:col-6 xl:col-6 lg:col-6 sm:col-12 p-0">
                    <div class="wrap-left">
                       <div class="grid grid-nogutter">
                            <div class="md:pr-1 sm:pr-0 col-12 md:col-4 sm:col-12 ">
                                <p-iconField iconPosition="right">
                                    <p-inputIcon (click)="onSearchClick()"  styleClass="pi pi-search" />
                                    <input [(ngModel)]="searchText" (keydown)="onEnter($event)" type="text" pInputText placeholder="Search" />
                                </p-iconField>
                            </div>
                            <div class="my-2 md:my-0 md:pl-2 sm:pl-0 col-12 md:col-4 sm:col-12">
                                <p-dropdown  [(ngModel)]="selectedSortOrder"  (onChange)="onSortChange($event)" [options]="sortings" optionLabel="name"
                                placeholder="Sort By" />
                                
                            </div>
                            @if(showMultiTenantFeature.enabled){
                                <div class="my-2 md:my-0 md:pl-2 sm:pl-0 col-12 md:col-4 sm:col-12">
                                    <p-dropdown [(ngModel)]="selectedUserFilter"  (onChange)="onFilterChange($event)" [options]="userFilters" optionLabel="name"
                                    placeholder="Filter" />
                                </div>    
                            }
                            <div class="p-0 col-12 md:col-12 sm:col-12">
                                <ul class="chips">
                                    @if(displaySearchTextChip){
                                        <li><p-chip [label]="searchTextDisplaying" (onRemove)="onRemoveChip('searchText')" [removable]="true" /></li>
                                    }
                                    @if(selectedSortOrder && selectedSortOrder.name){
                                        <li><p-chip [label]="selectedSortOrder.name" (onRemove)="onRemoveChip('sortOrder')" [removable]="true" /></li>
                                    }
                                    @if(selectedUserFilter && selectedUserFilter.name){
                                        <li><p-chip [label]="selectedUserFilter.name" (onRemove)="onRemoveChip('userFilter')" [removable]="true" /></li>
                                    }
                                    </ul>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="data-table">
                <p-table styleClass="p-datatable-sm p-datatable-sm--normal"
                #reviewerTable
                [value]="reviewers" 
                [lazy]="true"
                [rows]="10"
                [paginator]="true"
                [totalRecords]="reviewerCount"
                (onLazyLoad)="getReviewers($event)"
                [showCurrentPageReport]="true" 
                [paginator]="true" 
                [tableStyle]="{ 'min-width': '50rem' }"
                    currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                    autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="firstName" style="width:15%">
                                Name <p-sortIcon field="firstName" />
                            </th>
                            <th pSortableColumn="email" style="width:15%">
                                Email <p-sortIcon field="email" />
                            </th>
                            <th pSortableColumn="averageRating" style="width:15%">
                                <p-sortIcon field="averageRating" />
                                
                                Rating
                            </th>
                           
                            @if(showMultiTenantFeature.enabled){
                                <th pSortableColumn="status" style="width:10%">
                                    <p-sortIcon field="status" />
                                    Status
                                </th>
                            }
                            <th style="width:15%">
                                Actions
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-reviewer>
                        <tr>
                            <td><span class="cursor-pointer" (click)="openReviewerDialog(reviewer._id)">
                                {{reviewer.fullName}}
                            </span></td>
                            <td><span class="cursor-pointer" (click)="openReviewerDialog(reviewer._id)">{{reviewer.email}}</span></td>
                            <td>
                                <p-rating [(ngModel)]="reviewer.averageRating" [readonly]="true" [cancel]="false" class="cursor-default"/>
                            </td>
                            @if(showMultiTenantFeature.enabled){
                                <td>
                                    @if(reviewer.status) {
                                        <p-tag value="Active" [rounded]="true" class="activeStatus" />
                                    }
                                    @else {
                                        <p-tag value="Inactive" [rounded]="true" class="pillInactive" />
                                    }
                                </td>

                            }
                            <td>
                                <div class="wrap-actions">
                                    @if(selectedUserFilter && selectedUserFilter.value !== 'archive') {
                                        <p-button [pTooltip]="reviewer.workedTogether? 'Remove from Worked Together': 'Add to Worked Together'" tooltipPosition="top" (click)="moveToWorkedTogetherList($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span [class.freshgreen]="!reviewer.workedTogether" [class.light-grey]="reviewer.workedTogether" class=" material-symbols-outlined ">
                                                group
                                            </span>
                                        </p-button>
                                        <p-button [pTooltip]="reviewer.isFavourite? 'Remove from favorite': 'Add to favorite'" tooltipPosition="top" (click)="favouriteUser($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span class="material-symbols-outlined" [class.vibrant-pink]="reviewer.isFavourite" [class.light-grey]="!reviewer.isFavourite">
                                                bookmark_star
                                            </span>
                                        </p-button>
                                    
                                        <p-button  pTooltip="Restrict Candidate" tooltipPosition="top"  [disabled]="reviewer.isRestricted" (click)="moveToRestrictedList($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span class="material-symbols-outlined" [ngStyle]="{'color': reviewer.isRestricted ? 'red' : ''}">
                                                block
                                            </span>
                                        </p-button>
                                        @if(reviewer.projectId && reviewer.positionId){
                                            <p-button pTooltip="Fire Candidate" tooltipPosition="top" (click)="fireAndQuit($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                                <span class="material-symbols-outlined">
                                                    account_circle_off
                                                </span>
                                            </p-button>
                                        }
                                    }
                                    @if(selectedUserFilter && selectedUserFilter.value === 'archive'){
                                        <p-button [pTooltip]="'Unarchive'" tooltipPosition="top" (click)="showDeleteReviewerConfirmation($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span class="material-symbols-outlined">
                                                unarchive
                                            </span>
                                        </p-button>
                                    } @else {
                                        <p-button [pTooltip]="'Archive'" tooltipPosition="top" (click)="showDeleteReviewerConfirmation($event,reviewer)" [rounded]="true" [text]="true" icon="true">
                                            <span class="red material-symbols-outlined">
                                                delete
                                            </span>
                                        </p-button>
                                    }

                                    
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<p-dialog [modal]="true" draggable="false" [(visible)]="openUnarchiveModal"
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" [style]="{ width: '800px' }">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Confirmation
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="body">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="white-space-nowrap">
                Are you sure you want to unarchive {{selectedReviewer?.firstName}} {{selectedReviewer?.lastName}}?
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button label="NO" size="large" [rounded]="true" (onClick)="confirmUnarchiveUser()" />
        <p-button label="YES" size="large" [rounded]="true" (onClick)="confirmUnarchiveUser(true)" />
    </ng-template>
</p-dialog>
