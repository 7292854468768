<div *ngIf="projectPositionsFilled" class="flex flex-row justify-content-end">
    <p-button [link]="true" (click)="openGroupChat()" [rounded]="true" class="flex primary-background primary white-space-nowrap send-message-color">
        <span class="material-symbols-outlined "> forum </span>
        Send Group Message
    </p-button>
    <p-button [link]="true" (click)="showMailDialog(projectDetails._id, emailType.project, projectDetails.firm, 'All Staff', '', '')" [rounded]="true" class="ml-3 flex primary-background primary white-space-nowrap send-message-color">
        <span class="material-symbols-outlined">
            mail
        </span>
        Send Group Email
    </p-button>
</div>
<div class="table-options align-items-end justify-content-end">
</div>

<p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="projectStaffList" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
  [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
  (onRowCollapse)="onRowCollapse($event)">
  <ng-template pTemplate="header">
      <tr>
          <th pSortableColumn="name">
              Role
              <p-sortIcon field="name" />
          </th>
          <th pSortableColumn="code">
              Position
              <p-sortIcon field="code" />
          </th>
          <th pSortableColumn="startAt">
            Start Date
            <p-sortIcon field="startAt" />
          </th>
          <th style="width: 8%">Action</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" class="border-0" let-projectStaffList let-expanded="expanded">
      <tr>
          <td >
            <div class="wrap-check-data flex align-items-center gap-3">
                  {{ projectStaffList.roleType }}
            </div>
          </td>
          <td>{{ projectStaffList.positions || 0}} Positions
              Listed/{{ projectStaffList.positionsFilled }} Filled</td>
          <td>{{ projectStaffList.startAt | date : 'MM/dd/yyyy' }}</td>
          <td>
              <div class="wrap-actions flex align-items-center gap-2">
                @if(projectStaffList.status === roleStatus.active){
                    <div  pTooltip="End Role" 
                    tooltipPosition="top">
                      <p-button (click)="openDeleteModal(projectStaffList)" [rounded]="true" [text]="true" icon="true" plain="true">
                        <span class="red material-symbols-outlined">
                            delete
                        </span>
                        </p-button>
                    </div>
                }
                  @if(projectStaffList.status !== 'ARCHIVE'){
                    <p-button pTooltip="Send Mail" 
                    tooltipPosition="top" [rounded]="true" (click)="showMailDialog(projectDetails._id, emailType.role, projectDetails.firm, projectStaffList.roleType, projectStaffList._id, '')" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            mail
                        </span>
                    </p-button>
                    <p-button pTooltip="Message Role" 
                    tooltipPosition="top" (click)="openCreateChat(projectStaffList)"   [rounded]="true" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            forum
                        </span>
                    </p-button>
                  }
                  <p-button [ngStyle]="{'margin-left': 'auto'}" [pTooltip]="expanded? 'Close' : 'Expand'" 
                  tooltipPosition="top" type="button" pRipple [pRowToggler]="projectStaffList" [text]="true"
                      [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'" 
                      [size]="'large'" class="row-toggler" />
              </div>
          </td>
      </tr>
  </ng-template>
 
    <ng-template pTemplate="rowexpansion" let-projectStaffList>
        @if(projectStaffList.positionsList.length > 0){
        <tr>
            <td colspan="7" class="p-0">
                <div class="inner-wrap surface-100 p-0">
                    <p-table [value]="projectStaffList.positionsList" dataKey="_id">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="Reviewer" class="pl-6">
                                    Candidate <p-sortIcon field="Reviewer" />
                                </th>
                                <th pSortableColumn="Date">
                                    Staff Date <p-sortIcon field="Date" />
                                </th>
                                <th pSortableColumn="Rate">
                                    Hourly Rate <p-sortIcon field="Rate" />
                                </th>
                                <th pSortableColumn="Status">
                                    Status <p-sortIcon field="Status" />
                                </th>
                                <th style="width: 11%" class="pr-40">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-position>
                            <tr class="table-data">
                                <td class="pl-6 cursor-pointer" (click)="openReviewerDialog(position.reviewer._id)">{{ position.reviewer.firstName }} {{ position.reviewer.lastName }}</td>
                                <td>
                                    @if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                                        @if (projectStaffList.status === 'ARCHIVE') {
                                            <p>Ended {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (projectStaffList.status !=='ARCHIVE' && (position.presumptive && position.presumptive.status !== 'APPROVED')) {
                                            <p>Accepted {{position.filledAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (projectStaffList.status !== 'ARCHIVE') {
                                            <p>Accepted {{position.filledAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                    }
                                    @if (position.status === 'QUIT' || position.status === 'FIRED' || position.status === 'COMPLETED' || position.status === 'OCCUPIED') {
                                        @if (position.status === 'QUIT') {
                                            <p>Quit {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (position.status === 'FIRED') {
                                            <p>Fired {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                        @if (position.status === 'COMPLETED') {
                                            <p>Ended {{position.finishedAt | date : 'MM/dd/yyyy'}}</p>
                                        }
                                    }
                                </td>
                                <td (click)="openReviewerDialog(position.reviewer._id)" class="cursor-pointer">
                                    Rate ${{projectStaffList.hourlyRate}}/{{projectStaffList.payType === 'salary' ? 'yr' : 'hr'}}
                                    @if (projectStaffList.overTime > 0) {
                                        <span>  (OT ${{projectStaffList.overTime}}/hr)</span>
                                    }
                                </td>
                                <td>
                                    <span class="tag-value {{ position.status | lowercase }}">
                                        {{ position.status | lowercase }}
                                    </span>
                                </td>
                                <td class="pr-40">
                                    
                                    <div class="wrap-actions wrap-buttons-expandable">
                                        @if(!user.firm.isDocusignEnabled){
                                            <p-button 
                                                [pTooltip]="getMessageNoOfDocumentUploadedByUser(position.reviewer)" 
                                                tooltipPosition="top" 
                                                [rounded]="true"
                                                [text]="true" 
                                                icon="true"
                                                (click)="openReviewerDocumentDetail(position.reviewer)"
                                                [disabled]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'NOT_UPLOADED'">
                                                <span class="material-symbols-outlined" 
                                                    [class.vibrant-pink]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'PARTIALLY_UPLOADED'" 
                                                    [class.dark-grey]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'NOT_UPLOADED'"
                                                    [class.freshgreen]="isUserCompletedOrPartiallyCompletedTheDocuments(position.reviewer) === 'UPLOADED'">
                                                    download
                                                </span>
                                            </p-button>
                                        }
                                        @if (projectStaffList.status !== 'ARCHIVE') {
                                            @if(position.status === 'FIRED' || position.status === 'QUIT'){
                                                <p-button pTooltip="Send Offer"
                                                tooltipPosition="top" (click)="openImmediateSendOfferModal(position.role, position.reviewer)" [rounded]="true" [text]="true" icon="true">
                                                    <span class="material-symbols-outlined">
                                                        send
                                                    </span>
                                                </p-button>
                                            }
                                            @if(position.status !== 'FIRED' && position.status !== 'QUIT' && position.status !== 'COMPLETED'){
                                                <p-button pTooltip="Fire" 
                                                tooltipPosition="top" (click)="showFireReviewerDialog($event, position.reviewer._id, position._id, position.role, position.reviewer.firstName, position.reviewer.lastName)" [rounded]="true" [text]="true" icon="true">
                                                    <span class="material-symbols-outlined">
                                                        person_off
                                                    </span>
                                                </p-button>    
                                                <p-button  pTooltip="Messaging" (click)="openCreateChatReviewer(position)" 
                                                tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                    <span class="material-symbols-outlined">
                                                        chat
                                                    </span>
                                                </p-button>
                                                <p-button pTooltip="Send Mail" 
                                                tooltipPosition="top" [rounded]="true" [text]="true" icon="true" (click)="showMailDialog(projectDetails._id, emailType.individual, projectDetails.firm, position.reviewer.firstName +' ' + position.reviewer.lastName, projectStaffList._id, position.reviewer._id)">
                                                    <span class="material-symbols-outlined">
                                                        mail
                                                    </span>
                                                </p-button>
                                            }  
                                        }
                                    </div> 
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
        }
    </ng-template>
</p-table>

<p-dialog 
    [modal]="true" 
    draggable="false"
    [(visible)]="visibleDeleteModal" 
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
    [style]="{ width: '660px', height: selectedDeleteRole?.status === roleStatus.archive ? '50vh' : 'auto' }" >

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
               Confirmation
            </span>
        </div>
    </ng-template>

    <div class="grid grid-nogutter">
        <div class="col-12 p-0 flex justify-content-between align-items-center">
            <p>  You are about to
                @if(selectedDeleteRole?.status === roleStatus.active){
                    End
                }@else {
                    Restart
                }
                <span class="font-bold">{{selectedDeleteRole?.roleType}}</span>
                Role:
            </p>
          </div>
        @if(selectedDeleteRole?.status === roleStatus.active){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
                <textarea placeholder="Comment or reason for role end to be sent to reviewer(s)." rows="3" [(ngModel)]="deleteRoleComment"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
            </div>
        }
        @if(isUnreadMessagesExist){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2" ng-if="vm.discussions.length">
                <p class="font-twntwo">
                    <b>Note: </b> There are unread messages from one or more reviewer whose role is about to end.
                    These messages will not be readable if you continue. To read messages click “Cancel” and
                    navigate to the messaging screen.
                </p>
            </div>
        }
        @if(selectedDeleteRole?.status === roleStatus.archive){
            <p>
                Please select a new start date and time for the role.
            </p>
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
            <input type="text" style="display: none;" />
            <p-floatLabel>
                <p-calendar 
                    [(ngModel)]="restartDate"
                    inputId="restart_date" 
                    [minDate]="roleMinDate"
                    [maxDate]="roleMaxDate"
                    placeholder="mm/dd/yy"/>
                <label for="restart_date">Start Date</label>
            </p-floatLabel>
        </div>
       
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
           
                                            <p-calendar 
                                            inputId="calendar-timeonly" 
                                            [(ngModel)]="restartTime"
                                            [timeOnly]="true" 
                                            hourFormat="12"
                                            inline="true"
                                            styleClass="slider-style"
                                            />
        </div>
    }
    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" size="large" [rounded]="true" (onClick)="hideDeleteModal()"/>
        <p-button label="Ok" size="large" [rounded]="true" (onClick)="deleteRole()"/>
    </ng-template>
</p-dialog>